import { IndexTable } from '../../../../common/components/tables/IndexTable';

import { ProductsIndexTableHeader } from '../ProductsIndexTableHeader';
import {
  ProductsIndexTableBody,
  ProductsIndexTableBodyProps
} from '../ProductsIndexTableBody';

function ProductsCompactIndexTable({
  changeProductsFilters,
  companyNanoId,
  isMyLibrary,
  onLightboxOpen,
  onProductAiSearch,
  onProductEditButtonMouseEnter,
  onProductSimilarSearch,
  onProductsSelect,
  onSelectedProductsSidebarClose,
  onSelectedProductsSidebarOpen,
  products,
  productsCacheKeys,
  productsFilters,
  productsSet,
  productsSetCacheKeys,
  updateProductCache,
  updateProductsSetCache
}: ProductsIndexTableBodyProps) {
  return (
    <IndexTable
      scope="products"
      scrollContainerClassName="border-t border-b xl:border bg-white dark:bg-gray-900 border-transparent overflow-auto xl:rounded-lg"
      tableClassName="min-w-full relative z-0 border-collapse"
    >
      <ProductsIndexTableHeader />

      <ProductsIndexTableBody
        changeProductsFilters={changeProductsFilters}
        companyNanoId={companyNanoId}
        isMyLibrary={isMyLibrary}
        onLightboxOpen={onLightboxOpen}
        onProductAiSearch={onProductAiSearch}
        onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
        onProductSimilarSearch={onProductSimilarSearch}
        onProductsSelect={onProductsSelect}
        onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
        onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
        products={products}
        productsCacheKeys={productsCacheKeys}
        productsFilters={productsFilters}
        productsSet={productsSet}
        productsSetCacheKeys={productsSetCacheKeys}
        updateProductCache={updateProductCache}
        updateProductsSetCache={updateProductsSetCache}
      />
    </IndexTable>
  );
}

export default ProductsCompactIndexTable;
